<template>
    <div class="visitantes pt-4">
        <div class="d-middle-bt gap-3 mb-3" style="min-width: 1030px;">
            <p class="text-black f-20 f-medium">Registro de visitantes</p>
            <div class="d-middle gap-3">
                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                    <template #content>
                        <template v-if="filtro.length">
                            <div class="d-flex flex-column gap-2 w-177px">
                                <p class="d-flex justify-content-end f-11 text-blue cr-pointer" @click="limpiarFiltro">Borrar filtro</p>
                                <div v-for="item, index in filtro" :key="index">
                                    <p class="f-medium text-86 f-12 border-bottom border-light mb-1">{{ item.titulo }}</p>
                                    <p class="f-light text-86 f-12">{{ item.nombre }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            Filtrar
                        </template>
                    </template>
                    <button-filter 
                    :filtering="filtro.length > 0"
                    @click="abrirModalFiltroVisitante"
                    />
                </el-tooltip>
                <div class="wm-300px">
                    <form @submit.prevent="obtenerVisitantes(null)">
                        <el-input v-model="search" placeholder="Buscar por número de cédula" size="small">
                            <template slot="prefix">
                                <i class="icon-search text-86 f-20 pr-2 cr-pointer9" @click="obtenerVisitantes" />
                            </template>
                        </el-input>
                    </form>
                </div>
            </div>
        </div>
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table
            :data="visitantes"
            height="calc(100vh - 180px)"
            style="width: 100%"
            @row-click="abrirDetalleVisitante"
            >
                <el-table-column sortable label="Nombre" width="350">
                    <template slot-scope="scope">
                        <div class="d-middle gap-3">
                            <img v-if="scope.row.foto" :src="scope.row.foto" alt="visitante-img" class="wh-32 rounded-circle obj-cover" @error="scope.row.foto = '/img/no-imagen/admin.svg'" />
                            <img v-else src="/img/no-imagen/admin.svg" alt="visitante-img" class="wh-32 rounded-circle obj-cover" @error="scope.row.foto = '/img/no-imagen/admin.svg'" />
                            <p>{{ scope.row.nombre }} {{ scope.row.apellido }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable label="Cedula" prop="cedula" width="150" />
                <el-table-column sortable label="Ingresos" prop="ingresos" width="270" align="center" />
                <el-table-column sortable label="Último ingreso" width="220">
                    <template slot-scope="scope">
                        <div>
                            <p>{{ scope.row.ultimoIngreso ? scope.row.ultimoIngreso : "---- ----- ----" }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="55">
                    <template slot-scope="scope">
                        <i class="icon-pencil-outline text-black mr-2 f-17" @click.stop="abrirEditarVisitante(scope.row)" />
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <template v-if="hasSearched">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No se han encontrado visitantes</p>
                        </template>
                        <template v-else>
                            <img src="/img/generales/sin_visitantes.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No se han registrado visitantes</p>
                        </template>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="obtenerVisitantes"
                />
            </el-table>
        </div>
        <modal-detalle-visitante
        ref="modalDetalleVisitante"
        :imagen="visitante.foto"
        :nombre="`${visitante.nombre} ${visitante.apellido}`"
        :documento="visitante.cedula"
        :total-ingresos="visitante.ingresos"
        :ingresado="visitante.user_creador"
        editable
        @editar="cambiarModalEditar"
        >
            <template slot="body">
                <div class="d-flex gap-2 mb-3">
                    <div class="wh-32 rounded-circle d-middle-center bg-purpura">
                        <i class="icon-calendar text-white f-20" />
                    </div>
                    <div>
                        <p class="f-15 text-black">Ultimo ingreso</p>
                        <p class="f-15 f-light text-86 ucfirst">{{ visitante.ultimoIngreso | helper-fecha('dddd, DD MMM YYYY - hh:mm a') }}</p>
                    </div>
                </div>
                <div v-if="visitante.viviendasBloqueadas.length" class="d-flex gap-2 mb-3">
                    <div class="wh-32 rounded-circle d-middle-center bg-danger">
                        <i class="icon-cancelar text-white f-20" />
                    </div>
                    <div>
                        <p class="f-15 text-black">Bloqueos</p>
                        <p class="f-15 f-light text-86 ucfirst">Visita restringida en las siguientes viviendas</p>
                        <p v-for="item,index in visitante.viviendasBloqueadas" :key="index" class="f-15 f-light text-danger ucfirst">{{ item.vivienda }}</p>
                    </div>
                </div>
                <div class="d-flex gap-2">
                    <div class="wh-32 rounded-circle d-middle-center bg-azul-vivienda">
                        <i class="icon-house-outline text-white f-20" />
                    </div>
                    <div>
                        <p class="f-15 text-black">Viviendas</p>
                        <p v-for="item,index in visitante.viviendas" :key="index" class="f-15 f-light text-86 ucfirst">{{ item.vivienda }}</p>
                    </div>
                </div>
            </template>
        </modal-detalle-visitante>
        <modal-filtro-visitante
        ref="modalFiltroVisitante"
        :agrupaciones="agrupaciones"
        :viviendas="viviendas"
        mostrar-filtro-vivienda
        @agrupacionSeleccionada="obtenerViviendasPorIdAgrupacion"
        @filtrar="aplicarFiltro"
        @limpiar="obtenerVisitantes(null)"
        />
        <modal-editar-visitante
        ref="modalEditarVisitante"
        :action="actualizarVisitante"
        />
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import modalDetalleVisitante from './partials/modalDetalleVisitante.vue';
import modalEditarVisitante from './partials/modalEditarVisitante.vue';
import modalFiltroVisitante from './partials/modalFiltroVisitante.vue';
import { Notification } from 'element-ui';
import Viviendas from '~/services/viviendas'
import {
    ObtenerVisitantes,
    ObtenerVisitante ,
    ActualizarVisitante
} from '~/services/visitantes'

export default {
    components: {
        InfiniteLoading,
        modalDetalleVisitante,
        modalEditarVisitante,
        modalFiltroVisitante,
    },
    data(){
        return {
            search: '',
            hasSearched: false,
            loading: false,
            state: {},
            page: 1,
            filtro: [],
            agrupaciones: [],
            viviendas: [],
            visitantes: [],
            visitante: {
                fechaUltimoIngreso: null,
                viviendas: [],
                viviendasBloqueadas: [],
            }
        }
    },
    async mounted(){
        this.obtenerAgrupaciones();
    },
    methods: {
        async aplicarFiltro(filtrado){
            this.filtro = filtrado.datos;
            await this.obtenerVisitantes();
            this.$refs.modalFiltroVisitante.toggle();
        },
        async obtenerAgrupaciones(){
            try {
                const { data } = await Viviendas.getViviendas()
                this.agrupaciones = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async obtenerViviendasPorIdAgrupacion(id_agrupacion = null){
            try {
                const params = { id_padre: id_agrupacion }
                const { data } = await Viviendas.getViviendasHijas(params)
                if(data.success) this.viviendas = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async actualizarVisitante(visitante){
            try {
                const params = {
                    idVisitante: visitante.id,
                    cedula: visitante.cedula,
                    nombre: visitante.nombre,
                    apellido: visitante.apellido,
                }
                const { data } = await ActualizarVisitante(params)
                if(data.success){
                    this.visitantes = this.visitantes.map(el => {
                        if(el.id == data.data.id){
                            el.nombre = data.data.nombre
                            el.apellido = data.data.apellido
                            el.cedula = data.data.documento
                        }
                        return el
                    })
                    this.$refs.modalEditarVisitante.toggle()
                }
            } catch (error){
                const { response: { data }} = error;
                const { mensaje, success } = data;
                if(!success) Notification({
                    message: mensaje,
                    type: 'error',
                })
            }
        },
        async obtenerVisitante(id){
            try {
                const params = {
                    idVisitante: id
                }
                const { data } = await ObtenerVisitante(params)
                if(data.success) this.visitante = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async obtenerVisitantes($state){
            try {
                this.loading = true

                if($state == null){
                    this.state.reset();
                    this.visitantes = [];
                    this.page = 1;
                    return
                }
                
                this.state = $state

                const params={
                    cedula: this.search,
                    idAgrupacion: this.$refs.modalFiltroVisitante.agrupacion_id,
                    idVivienda: this.$refs.modalFiltroVisitante.vivienda_id,
                    page: this.page
                }
                const {data} = await ObtenerVisitantes(params);

                if(data.data.data.length){
                    this.page += 1;
                    this.visitantes.push(...data.data.data)
                    this.state && this.state.loaded();
                }else{
                    this.state && this.state.complete();
                }

                this.hasSearched = this.visitantes.length && this.search.trim() != ''
                
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.loading = false
            }
        },
        async abrirDetalleVisitante(e){
            const { id } = e;
            await this.obtenerVisitante(id)
            this.$refs.modalDetalleVisitante.toggle(id)
        },
        limpiarFiltro(){
            this.filtro = []
            this.$refs.modalFiltroVisitante.limpiarFiltro()
        },
        abrirEditarVisitante(row){
            this.$refs.modalEditarVisitante.toggle(row.id, row.nombre, row.apellido, row.cedula)
        },
        abrirModalFiltroVisitante(){
            this.$refs.modalFiltroVisitante.toggle();
        },
        cambiarModalEditar(){
            this.$refs.modalDetalleVisitante.toggle(this.visitante.id)
            this.$refs.modalEditarVisitante.toggle(this.visitante.id, this.visitante.nombre, this.visitante.apellido, this.visitante.cedula)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>