<template>
    <modal ref="modalEditarRegistro" titulo="Editar registro" guardar tamano="modal-md" adicional="Guardar" @adicional="actualizar">
        <ValidationObserver ref="formulario">
            <div class="px-3">
                <div class="d-flex flex-column gap-3 mb-2">
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:20" name="nombre">
                            <label for="nombre" class="d-block pl-2 text-general f-12">Nombre</label>
                            <el-input id="nombre" v-model="nombre" max="20" />
                            <p class="d-block f-10 text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="apellido">
                            <label for="apellido" class="d-block pl-2 text-general f-12">Apellido</label>
                            <el-input id="apellido" v-model="apellido" />
                            <p class="d-block f-10 text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="cedula">
                            <label for="cedula" class="d-block pl-2 text-general f-12">Cédula</label>
                            <el-input id="cedula" v-model="cedula" />
                            <p class="d-block f-10 text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
export default {
    props: {
        action: {
            type: Function,
            default: () => {}
        }
    },
    data(){
        return {
            id: '',
            nombre: '',
            apellido: '',
            cedula: '',
        }
    },
    methods: {
        toggle(id, nombre, apellido, cedula){
            this.id = id
            this.cedula = cedula
            this.nombre = nombre
            this.apellido = apellido
            this.$refs.modalEditarRegistro.toggle()
        },
        async actualizar(){
            const valid = await this.$refs.formulario.validate();
            if(!valid) return;
            await this.action({
                id: this.id,
                nombre: this.nombre,
                apellido: this.apellido,
                cedula: this.cedula,
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>